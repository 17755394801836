import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const OurOffers = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // useEffect to update the fade state when the element comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  const scrollToContactForm = () => {
    let scrollMultiplier = window.innerWidth >= 768 ? 1.457 : 1.05; // Adjust the breakpoints and multipliers as needed

    const scrollDistance = window.innerHeight * scrollMultiplier;

    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    let scrollMultiplier = window.innerWidth >= 768 ? 0 : 0; // Adjust the breakpoints and multipliers as needed

    const scrollDistance = window.innerHeight * scrollMultiplier;

    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  };

  const plans = [
    {
      name: "The Package",
      desc: "With Hassoni Exclusive Coaching, you will have access to an app and the features below!",
      price: "?",
      isMostPop: true,
      features: [
        "Customized training programs",
        "Nutrition plans",
        "Daily checkups",
        "Setting up goals and habits",
        "Personalized coaching and support",
        "Effective techniques for managing stress",
        "Enhancing flexibility and strength",
      ],
    },
  ];

  const handlePackageChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section
      ref={ref}
      className={`relative py-14 poppins  ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div
        className="absolute top-0 w-full h-[521px] hidden lg:block"
        style={{
          background:
            "linear-gradient(152.92deg, rgba(146, 206, 245, 0.2) 4.54%, rgba(180, 220, 242, 0.17) 34.2%, rgba(146, 206, 245, 0.1) 77.55%)",
        }}
      ></div>
      <div className="max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8 hidden lg:block">
        <div className="relative max-w-xl mx-auto space-y-3 px-4 sm:text-center sm:px-0">
          <h3 className="text-cyan-600 poppins">Subscription</h3>
          <p className="text-gray-800 text-3xl poppins sm:text-4xl">
            Features that will include in your subscription
          </p>
          <div className="max-w-xl">
            <p>
              Let this decision be your best decision. This is where your
              journey starts. Reach your full potential with Hassoni Exclusive.
            </p>
          </div>
        </div>
        <div className="mt-16 justify-center sm:flex ">
          {plans.map((item, idx) => (
            <div
              key={idx}
              className={`relative flex-1 flex items-stretch flex-col mt-6 sm:mt-0 sm:rounded-xl sm:max-w-md ${
                item.isMostPop ? "bg-white shadow-lg sm:border" : ""
              }`}
            >
              <div className="p-4 py-8 space-y-4 border-b md:p-8">
                <span className="text-cyan-600 ">{item.name}</span>
                {/*<div className="text-gray-800 text-3xl ">
                  ${item.price}{" "}
                  <span className="text-xl text-gray-600 ">
                    {item.isMostPop ? "/year" : "/month"}
                  </span>{" "}
            </div> */}
                <p>{item.desc}</p>
                <button
                  onClick={scrollToTop}
                  className="px-3 py-3 rounded-lg w-full  text-sm duration-150 text-white bg-cyan-600 hover:bg-cyan-500 active:bg-cyan-700"
                >
                  {item.isMostPop ? "Join now🥇!" : "Get started💪!"}
                </button>
              </div>
              <ul className="p-4 py-8 space-y-3 md:p-8">
                <li className="pb-2 text-gray-800 ">
                  <p>Features</p>
                </li>
                {item.features.map((featureItem, idx) => (
                  <li key={idx} className="flex items-center gap-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-cyan-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {featureItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <section
        ref={ref}
        className={`relative -mt-32 py-14 poppins lg:hidden ${
          fadeIn ? "opacity-100" : "opacity-0"
        } transition-opacity duration-1000`}
      >
        <div
          className="absolute top-0 w-full h-[521px]"
          style={{
            background:
              "linear-gradient(152.92deg, rgba(146, 206, 245, 0.2) 4.54%, rgba(180, 220, 242, 0.17) 34.2%, rgba(146, 206, 245, 0.1) 77.55%)",
          }}
        ></div>
        <div className="max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8">
          <div className="relative max-w-xl mx-auto space-y-3 px-4 sm:text-center sm:px-0">
            <h3 className="text-cyan-600 poppins">Subscription</h3>
            <p className="text-gray-800 text-3xl poppins sm:text-4xl">
              Menu/pricing
            </p>
            <div className="max-w-xl">
              <p>
                Let this decision be your best decision. This is where your
                journey starts. Reach your full potential with Hassoni
                Exclusive.
              </p>
            </div>
          </div>
          <div className="mt-16 justify-center sm:flex">
            <div className="relative flex-1 flex items-stretch flex-col mt-6 sm:mt-0 sm:rounded-xl sm:max-w-md bg-white shadow-lg sm:border">
              <div className="p-4 py-8 space-y-4 border-b md:p-8">
                <span className="text-cyan-600">
                  {plans[currentIndex].name}
                </span>
                <p>{plans[currentIndex].desc}</p>
                <button
                  onClick={scrollToContactForm}
                  className="px-3 py-3 rounded-lg w-full text-sm duration-150 text-white bg-cyan-600 hover:bg-cyan-500 active:bg-cyan-700"
                >
                  {plans[currentIndex].isMostPop
                    ? "Join now🥇!"
                    : "Get started💪!"}
                </button>
              </div>
              <ul className="p-4 py-8 space-y-3 md:p-8">
                <li className="pb-2 text-gray-800">
                  <p>Features</p>
                </li>
                {plans[currentIndex].features.map((featureItem, idx) => (
                  <li key={idx} className="flex items-center gap-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-cyan-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    {featureItem}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="flex justify-center mt-4 space-x-4">
            {plans.map((_, index) => (
              <button
                key={index}
                className={`text-sm px-3 py-1 rounded-lg duration-150 ${
                  currentIndex === index
                    ? "text-cyan-600 bg-cyan-100"
                    : "text-gray-600 hover:text-cyan-600"
                }`}
                onClick={() => handlePackageChange(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};

export default OurOffers;
