import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const AboutHE = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // useEffect to update the fade state when the element comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  const scrollToContactForm = () => {
    const scrollDistance = window.innerHeight * 1.457; // Adjust the multiplier as needed
    window.scrollTo({
      top: scrollDistance,
      behavior: "smooth",
    });
  };

  return (
    <div
      ref={ref}
      className={`text-gray-900 mt-32 lg:my-20 ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div className="lg:max-w-5xl flex flex-col items-center mx-auto  lg:my-0">
        <h1 className="text-4xl lg:text-8xl montserrat mb-8 relative z-10 mx-7 lg:mx-0">
          Why Hassoni exclusive?
        </h1>
        <img
          src="/assets/Rectangles.svg"
          className="absolute z-0 lg:-mt-28 lg:w-60 lg:h-60 w-32 h-32 -mt-16 lg:ml-72 floating-effect"
          alt="Online Coaching"
        />
        <div className="w-full flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 px-6 lg:pr-16">
            <p className="text-gray-800 text-lg lg:text-md mb-6 mt-10 poppins">
              🏆 Ignite Your Fitness Journey with Over a Decade of Expertise! 🌟
              <br />
              <br />
              With 8000+ client hours in the last 4 years and the prestigious
              Personal Trainer of the Year award from Scandinavia’s top gym
              company, my record speaks volumes. Discover the power of
              bulletproof training, 2-4 times a week, 20-45 minutes each session
              – no fluff, no restrictive diets.
              <br />
              <br />
              At Hassoni Exclusive, I’m all in for your success. Experience
              unwavering support and expert guidance tailored to your unique
              goals. As your dedicated personal trainer, I guarantee responses
              within 10 hours, often even quicker.
              <br />
              <br />
              Join our vibrant community at Hassoni Exclusive and dive into a
              personalized, dynamic fitness journey. Let’s combine our energies
              to conquer your goals and unleash your ultimate potential. 🚀
            </p>
          </div>

          <div className="w-full lg:w-1/2">
            <img
              src="assets/Abdul1.png"
              className="rounded-3xl shadow-xl mx-auto h-auto max-w-full lg:max-h-full lg:rounded-lg lg:shadow-2xl lg:mx-0 lg:w-full"
              alt="Online Coach"
            />
          </div>
        </div>
      </div>
      <button
        className="poppins hidden lg:block fixed bottom-4 right-4 bg-cyan-500 hover:bg-cyan-600 text-white py-2 px-4 rounded shadow z-10"
        onClick={scrollToContactForm}
      >
        Book Consultation!
      </button>
    </div>
  );
};

export default AboutHE;
