import React from "react";

export const Footer = () => {
  return (
    <div className="relative mt-28 bg-[#303030] poppins text-white z-20">
      <svg
        className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-[#303030]"
        preserveAspectRatio="none"
        viewBox="0 0 1440 54"
      >
        <path
          fill="currentColor"
          d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
        />
      </svg>
      <div className="flex flex-col justify-center items-center ">
        <img src="/assets/orglogo.png" className="w-96" alt="logo" />

        <div className="flex flex-col justify-between pb-10 sm:flex-row">
          <a
            href="https://www.instagram.com/hassoniexclusive/"
            target="_blank"
            class="transition hover:opacity-75 mr-5"
          >
            INSTAGRAM{" "}
          </a>
          <a
            href="https://www.tiktok.com/@abbehassoni"
            target="_blank"
            class="transition hover:opacity-75 mr-5"
          >
            TIKTOK{" "}
          </a>
          <a
            href="https://www.instagram.com/abdulhassonii/"
            target="_blank"
            class="transition hover:opacity-75"
          >
            PRIVATE INSTAGRAM
          </a>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center ">
        <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
          <ul class="flex flex-wrap justify-center gap-4 text-xs lg:justify-end">
            <li>
              <a href="#" class="transition hover:opacity-75">
                Copyright ©2023 Hassoni Exclusive AB
              </a>
            </li>
            <li>
              <a
                href="https://aekit.se/"
                target="_blank"
                class="transition hover:opacity-75"
              >
                Webdesign by AEKIT
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
