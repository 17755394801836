import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Clients = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // useEffect to update the fade state when the element comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`text-gray-900 mb-20 poppins py-10 mt-20 lg:mt-10 ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div className="lg:max-w-5xl flex flex-col items-center mx-auto my lg:my-0">
        <h2 className="text-4xl lg:text-8xl montserrat relative z-10">
          Client results
        </h2>
        <span className="text-sm montserrat mb-20 relative z-10 poppins">
          (scroll sideways)
        </span>
        <img
          src="/assets/clientr.svg"
          className="absolute -z-10 lg:w-60 lg:h-60 w-32 h-32 -mt-24 mr-52 lg:mr-[650px] floating-effect2"
          alt="Training"
        />
      </div>
      <div className="carousel rounded-box ">
        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl"
            src="assets/client1.png"
            alt="Body Transformation"
          />
        </div>
        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl"
            src="assets/client5.png"
            alt="Body Transformation"
          />
        </div>

        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl mr-1"
            src="assets/client4.png"
            alt="Body Transformation"
          />
        </div>
        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl mr-1"
            src="assets/client2.png"
            alt="Body Transformation"
          />
        </div>
        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl mr-1"
            src="assets/client2s.png"
            alt="Body Transformation"
          />
        </div>
        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl mr-1"
            src="assets/client6.png"
            alt="Body Transformation"
          />
        </div>
        <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl mr-1"
            src="assets/client7.png"
            alt="Body Transformation"
          />
        </div>
        {/* <div className="carousel-item">
          <img
            className="w-[400px] h-[400px] rounded-3xl mr-1"
            src="assets/client2ss.png"
            alt="Body Transformation"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Clients;
